
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import mapboxgl, { GeoJSONSource, LngLatLike } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { LocationData } from '@/types/locations';
import LocationCard from '../LocationCard/LocationCard.vue';
import { Nullable } from '@/types/misc';
import { countries } from '@/statics/countries';
import { Coordinate, Country } from '@/types/country';
import { GeoJSON, Point } from 'geojson';
import { cloneDeep } from 'lodash';
import { customTooltip } from '@/utils/dom';

@Component({
  components: { LocationCard },
})
export default class Map extends Vue {
  @Prop({ required: true, type: Array, default: () => [] })
  locations!: LocationData[];
  @Prop({ required: true, type: Array, default: () => [] })
  selectedLocations!: LocationData[];
  @Prop({ required: true, type: Array, default: () => [] })
  filteredLocations!: LocationData[];
  @Prop() activeLocationId!: string;
  @Prop() detailedLocationId!: string;
  @Prop() showMapView!: boolean;

  public map: Nullable<mapboxgl.Map> = null;
  mapId = 'map-id';
  locationPinIcon: Record<string, string> = {
    'cinema': 'cinema',
    'cafe': 'cafe',
    'super-market': 'super-market',
    'gym-men': 'gym-men',
    'gym-women': 'gym-women',
    'street': 'street',
    'gas-station': 'gas-station',
    selected: 'selected-pin'
  };

  public geojson = {
    type: "FeatureCollection",
    features: this.locations.map((location) => ({
      type: 'Feature',
      properties: {
        id: `place-${ location.ID }`,
        ID: location.ID,
        type: 'Feature',
        place_type: ['place'],
        relevance: 1,
        'text_en-US': location.NAME,
        'language_en-US': 'en',
        'place_name_en-US': location.NAME,
        text: location.NAME,
        language: 'en',
        place_name: location.NAME,
        center: location.COORDINATES,
        locationIcon:this.locationPinIcon[location.TYPE.VAL],
        locationType:location.TYPE.VAL,
        properties: {
          title: location.NAME,
          description: this.locations[1].COORDINATES,
        },
      },
      geometry: {
        type: 'Point',
        coordinates: [location.COORDINATES.lng, location.COORDINATES.lat],
      },
    }))
  };

  geojsonFeaturesMap = this.geojson.features.reduce((featuresMap, feature) => {
    return {
      ...featuresMap,
      [feature.properties.ID]: feature,
    }
  }, {} as Record<string, any>)
  filteredFeaturesGeojson = cloneDeep(this.geojson)

  @Watch('selectedLocations')
  async selectedLocationsOnMap(selectedLocations: LocationData[]) {
    this.cachedClustersMarkers = {};
    this.locations.forEach(({ ID, TYPE }, index) => {
      const locationMarker = document.querySelector(`#place-${ ID }.marker`);
      const isLocationSelected = selectedLocations.find(
        ({ ID: selectedLocationId }) => selectedLocationId === ID
      );
      this.geojsonFeaturesMap[ID].properties.locationIcon =
        isLocationSelected ? this.locationPinIcon.selected : this.locationPinIcon[TYPE.VAL];
      if (!locationMarker) {
        return;
      }
      /*
      if [location is selected & pin element not have selected class]

      do [add selected class to the pin element]

      I add this class because it has the background image for the SVG icon.
      * */
      if (
        isLocationSelected &&
        !locationMarker.classList.contains(this.locationPinIcon.selected)
      ) {
        locationMarker.classList.add(this.locationPinIcon.selected);
      }
      /*

       if [location is not selected & pin element have selected class]
       do [remove selected class from the pin element & add the corresponding class based on location type]
       I remove this class to not show the selected location icon on the location that is not selected.
       I add this class because it has the background image for the SVG icon.
      * */
      else if (
        !isLocationSelected &&
        locationMarker.classList.contains(this.locationPinIcon.selected)
      ) {
        locationMarker.classList.remove(this.locationPinIcon.selected);
        locationMarker.classList.add(this.locationPinIcon[TYPE.VAL]);
      }
    });
    await this.rerenderClustersMarkers()
    /*
    if [there is many selected locations]
    do [move the map to fill in the selected locations]
    * */
    if (selectedLocations.length > 1) {
      const bounds = this.map!.getCenter().toBounds(0);
      selectedLocations.map((location) => this.geojsonFeaturesMap[location.ID])
        .forEach((feature) => {
          const [lng = 0, lat = 0] = feature.geometry.coordinates || {};
          const lngLat = new mapboxgl.LngLat(lng, lat);
          bounds.extend(lngLat);
          this.map!.fitBounds(bounds, { padding: 100 });
        });
    }
    /*
    if [there is only one selected location]
    do [move the map to make the selected location at the center of the map]
    * */
    else if (selectedLocations.length === 1) {
      const [selectedLocation] = selectedLocations;
      const selectedMarker = this.geojsonFeaturesMap[selectedLocation.ID];
      const locationPoint = selectedMarker?.geometry.coordinates || [];
      this.map!.flyTo({
        center: locationPoint as LngLatLike,
        zoom: 15,
        bearing: 0,
        speed: 2,
        curve: 1,
        easing: function (t) {
          return t;
        },
        essential: true,
      });
    }
  }

  // objects for caching and keeping track of HTML marker objects (for performance)
  cachedClustersMarkers: Record<number | string, mapboxgl.Marker> = {};
  markersOnScreen: Record<number | string, mapboxgl.Marker> = {};

  getClusterSelectedLocations(mapSource: GeoJSONSource, id: number) {
    return new Promise((resolve, reject) => {
      mapSource.getClusterLeaves(id, Infinity, 0, (errors, clusterFeatures) => {
        if (errors) {
          return reject(errors)
        }
        for (const clusterFeature of clusterFeatures) {
          /*
          if [location icon have the selected class]
          do [return true from this function and break the loop]
          * Found location in clustered point selected.
          * */
          if (this.geojsonFeaturesMap[clusterFeature.properties?.ID]?.properties.locationIcon === this.locationPinIcon.selected) {
            resolve(true)
            break;
          }
        }
        resolve(false)
      })
    })
  }
  rerenderClustersIcons(map:mapboxgl.Map){
    return new Promise((resolve, reject)=>{
      map?.on('render', async () => {
        if (!map?.isSourceLoaded(this.mapId)) return resolve();
        await this.rerenderClustersMarkers()
        resolve()
      });
    })
  }
  updateMarkersPosition(features: mapboxgl.MapboxGeoJSONFeature[], newlyAddedClustersMarkers: Record<number|string, mapboxgl.Marker>) {
    return new Promise(async (resolve, reject) => {
      for (const feature of features) {
        const point = feature.geometry as Point
        const coords = point.coordinates;
        const props = feature.properties;
        /*
        if [feature is not clustered]
        do [create a pin element for a non-clustered pin if pin is not on the map]

        cachedClusterMarker have the pin that is already processed.
        * */
        if (!props?.cluster) {
          const id = props?.id as string;
          let cachedClusterMarker = this.cachedClustersMarkers[id];
          /*
          if [the location pin is not on the map]
          do [create pin element with corresponding class based on location type]
          Check if the location pin is already on the map to enhance performance and not remove and add it again.
          * */
          if (!cachedClusterMarker) {
            const markerEl = document.createElement('div');
            markerEl.id = id;
            markerEl.className = `marker ${ this.geojsonFeaturesMap[props?.ID].properties.locationIcon || '' } ${ props?.locationType }`;
            markerEl.addEventListener('click', () => {
              this.$emit('update:detailedLocationId', props?.ID);
              this.$emit('update:activeLocationId', props?.ID);
            });
            const popupEl = (this.$refs[id] as Vue[])[0]?.$el;
            const popup = new mapboxgl.Popup({ offset: 25 })
              // @ts-ignore
              .setHTML(popupEl?.outerHTML);
            customTooltip({
              id: `tooltip${ id }`,
              innerText: this.$t(props?.locationType).toString(),
              initialClassName: 'tooltip opacity-0 scale-0 ',
              className: 'tooltip d-lg-inline-block d-none',
              hideClassName: 'tooltip opacity-0',
              animationDuration: 300,
              parentElement: markerEl,
            });
            cachedClusterMarker = this.cachedClustersMarkers[id] = new mapboxgl.Marker({
              element: markerEl
            }).setLngLat([coords[0], coords[1]]).setPopup(popup);

          }
          newlyAddedClustersMarkers[id] = cachedClusterMarker;
          /*
           if [the pin marker is not on the map]
           do [add the pin to map and in the markersOnScreen to prevent adding the same pin many times over each other]
          * Add the location pin if not on the map.
          * */
          if (!this.markersOnScreen[id]) {
            this.markersOnScreen[id] = cachedClusterMarker;
            cachedClusterMarker.addTo(this.map as mapboxgl.Map);
          }
        }
        /*
        if [feature is clustered]
        do [create a pin element for a clustered pin if pin is not on the map]
          Create a pin element for a clustered pin.
        * */
        else {
          const id = props.cluster_id;
          let cachedClusterMarker = this.cachedClustersMarkers[id];
          /* This variable is the cluster id that differentiates between the marked cluster icon and the unmarked cluster icon
           in the cached cluster marker otherwise, marked and unmarked cluster id will have the same value.

           The marked cluster is the cluster that has at least one selected location,
            and it turns into a selected pin instead of the pin that has a number of children's locations.
          * */
          let clusterIdWithTypeOfPinIconSuffix = id;

          /*
          if [the location pin is not on the map]
          do [create pin element either selected if there is one location selected on its children locations,
           or pin that have a number inside it indicates the number of location under this cluster pin]
          Check if the clustered pin is already on the map to enhance performance and not remove and add it again.
          * */
          if (!cachedClusterMarker) {
            const mapSource = this.map?.getSource(this.mapId) as GeoJSONSource;
            const foundSelectedIconInsideCluster = await this.getClusterSelectedLocations(mapSource, id);
            const el = foundSelectedIconInsideCluster ? this.createSelectedImage() : this.createLocationCountImage(props?.point_count);
            clusterIdWithTypeOfPinIconSuffix += foundSelectedIconInsideCluster ? '-marked-pin' : '-unmarked-pin'

            /*
            * Zoom to a level that cluster can open one level.
            * */
            el.addEventListener('click', () => {
              mapSource?.getClusterExpansionZoom(
                id,
                (err, zoom) => {
                  if (err) return;
                  const geometry = feature.geometry as Point
                  this.map?.easeTo({
                    center: geometry.coordinates as [number, number],
                    zoom: zoom
                  });
                }
              );
            })
            cachedClusterMarker = this.cachedClustersMarkers[clusterIdWithTypeOfPinIconSuffix] = new mapboxgl.Marker({
              element: el
            }).setLngLat([coords[0], coords[1]]);
          }
          newlyAddedClustersMarkers[clusterIdWithTypeOfPinIconSuffix] = cachedClusterMarker;
          /*
          if [the pin marker is not on the map]
           do [add the pin to map and in the markersOnScreen to prevent adding the same pin many times over each other]
          * Add the clustered pin if not on the map.
          * */
          if (!this.markersOnScreen[clusterIdWithTypeOfPinIconSuffix]) {
            this.markersOnScreen[clusterIdWithTypeOfPinIconSuffix] = cachedClusterMarker;
            cachedClusterMarker.addTo(this.map as mapboxgl.Map);
          }

        }
      }
      resolve()
    })
  }

  async rerenderClustersMarkers() {
    const newlyAddedClustersMarkers: Record<number | string, mapboxgl.Marker> = {};
    const features = this.map?.querySourceFeatures(this.mapId);
    if (!features)
      return;
    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    await this.updateMarkersPosition(features, newlyAddedClustersMarkers);
    // for every cluster's markers we've added previously, remove those that are no longer visible
    for (const id in this.markersOnScreen) {
      if (!newlyAddedClustersMarkers[id]) {
        this.markersOnScreen[id].remove();
        delete this.markersOnScreen[id]
      }
    }
  }

  createLocationCountImage(count: number) {
    let className = 'icon-20';
    if (count > 20 && count < 30) {
      className = 'icon-30'
    } else if (count > 30 && count < 40) {
      className = 'icon-40'
    } else if (count > 40 && count < 50) {
      className = 'icon-50'
    } else if (count > 50) {
      className = 'icon-60'
    }

    let html = `<div class="relative pointer ${ className }">
                  <span class="count-on-image">${ count }</span>
                  <img width="50" src="/assets/icons/location-pin.svg"/>
                </div>`;


    const el = document.createElement('div');
    el.innerHTML = html;
    return el;
  }

  createSelectedImage() {

    let html = `<div class="relative pointer">
                  <img width="60" src="/assets/icons/location-pin-selected.svg"/>
                </div>`;


    const el = document.createElement('div');
    el.innerHTML = html;
    return el;
  }

  getCountryZoomLevel(countryMapZoomLevel: Country['mapZoomLevel']): number {
    return this.$vuetify.breakpoint.lgAndUp ? countryMapZoomLevel.desktop : countryMapZoomLevel.mobile
  }
  public mounted() {
    try {
      const [ksa] = countries;
      mapboxgl.accessToken =
        'pk.eyJ1IjoiYmFoYWEtc2hhc2hhIiwiYSI6ImNrbXZ2Z3dzbjA4enYydnBxdDk4ZzV2MmQifQ.2sfVKOcvLOXrYyknaE5Lsg';
      const rtlStatus = mapboxgl.getRTLTextPluginStatus();
      if (rtlStatus === 'unavailable' || rtlStatus === 'error') {
        mapboxgl.setRTLTextPlugin(
          'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
          (error) => {
            if (error) {
              console.log(error);
            }
          },
          true // Lazy load the plugin
        );
      }
      this.map = new mapboxgl.Map({
        container: 'mapbox',
        style: 'mapbox://styles/youssefr/ckk99cqd0243u17jusv0rs545',
        center: ksa.coordinates as Coordinate,
        zoom: this.getCountryZoomLevel(ksa.mapZoomLevel),
      }).on('load', (event) => {
        event.target.resize();
        this.map?.addSource(this.mapId, {
          type: 'geojson',
          // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
          // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
          data: this.geojson as GeoJSON.FeatureCollection,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        });

        this.rerenderClustersIcons(this.map!);


        this.map?.addLayer({
          id: 'unclustered-point',
          type: 'symbol',
          source: this.mapId,
          filter: ['!', ['has', 'point_count']],
        });
      });
      const nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, 'top-right');


      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      });
      this.map!.addControl(geolocate, 'top-right');
      this.selectedLocationsOnMap(this.selectedLocations);
    } catch (error) {
      console.log('error', error);
    }
  }


  @Watch('showMapView')
  showMapViewChanged() {
    this.map?.resize()
  }


  public flyTo(location: LocationData) {
    this.map!.flyTo({
      // These options control the ending camera position: centered at
      // the target, at zoom level 9, and north up.
      center: location.COORDINATES,
      zoom: 15,
      bearing: 0,

      // These options control the flight curve, making it move
      // slowly and zoom out almost completely before starting
      // to pan.
      speed: 2, // make the flying slow
      curve: 1, // change the speed at which it zooms out

      // This can be any easing function: it takes a number between
      // 0 and 1 and returns another number between 0 and 1.
      easing: function (t) {
        return t;
      },

      // this animation is considered essential with respect to prefers-reduced-motion
      essential: true,
    });
  }

  @Watch('activeLocationId')
  public onChangeActiveLocationId(id: string) {
    const activeLocation = this.locations.find(
      (location) => location.ID === id
    );
    this.flyTo(activeLocation!);
  }

  @Watch('filteredLocations')
  filteredLocationsChanged(filteredLocations: LocationData[]) {
    const locationMap = filteredLocations.reduce((locations, location) => {
      return {
        ...locations,
        [location.ID]: location,
      }
    }, {} as Record<string, LocationData>)
    this.filteredFeaturesGeojson.features = this.geojson.features.filter(({ properties: { ID } }) => {
      return locationMap[ID]
    });
    const mapSource = this.map?.getSource(this.mapId) as GeoJSONSource;
    if (mapSource) {
      mapSource.setData(this.filteredFeaturesGeojson as GeoJSON.FeatureCollection);
    }
    if (this.locations.length === this.filteredLocations.length) {
      return;
    }
    /*
    Move the map to fill in the filtered locations.
    * */
    if (filteredLocations.length > 1) {
      const bounds = new mapboxgl.LngLatBounds();
      filteredLocations.forEach((location) => {
        const { lng = 0, lat = 0 } = location.COORDINATES || {};

        const lngLat = new mapboxgl.LngLat(lng, lat);
        bounds.extend(lngLat);
      this.map!.fitBounds(bounds, { padding: 100 });
      });
    }
    /*
    Move the map to make the filtered location at the center of the map.
    * */
    else if (filteredLocations.length == 1) {
      const [selectedMarker] = filteredLocations;
      const locationPoint = selectedMarker.COORDINATES || {};
      this.map!.flyTo({
        center: locationPoint,
        zoom: 15,
        bearing: 0,
        speed: 2,
        curve: 1,
        easing: function (t) {
          return t;
        },
        essential: true,
      });
    }
  }
}
